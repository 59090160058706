/* custom styles */
html,
body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

h1 {
  @apply text-2xl;
}
